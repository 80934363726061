.fh {
  .loader-hint {
    animation-name: fadein;
    animation-duration: 4s;
    animation-delay: 4s;
    animation-fill-mode: forwards;
    opacity:0;
  }
}

@keyframes fadein {
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}