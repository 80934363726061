@import '../../styles/consts.scss';

.navbar {
  .brand {
    cursor: unset;

    .brand-content {
      span {
        font-size: 14pt;
        text-transform: uppercase;
        color: #c4c8cf;
        margin-left: 10px;
      }
    }
  }
}