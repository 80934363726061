@import '../../../styles/consts.scss';

.fh {
  .modalMessage {
    min-height: 37px;
    padding: 10px;
    text-align: center;
    margin-top: 10px;
    transition: all 300ms;

    .message {
      opacity: 0;
    }

    &.visible {
      background-color: $fh-ebonyclay-dark;

      .message {
        opacity: 1;
      }
    }
  }
}