@import '../../styles/consts.scss';

.import-utility {
  color: white;

  .MuiBackdrop-root {
    z-index: 3;
  }

  .container {
    margin-top: 21px;
  }
  .dropzone-container.MuiGrid-root.MuiGrid-item {
    padding: 16px 16px 0px 16px;
  }
  .MuiDropzoneArea-root {
    min-height: 175px;
  }
  .example-file-download {
    text-align: right;
    color: white;
    text-decoration: none;
    line-height: 0%;

    .MuiTypography-root {
      display: flex;
      align-items: center;
      justify-content: flex-end
    }
  }
  .file-list.MuiGrid-root.MuiGrid-item {
    min-height: 53px;
    padding: 5px 16px 16px 16px;
  }
  .error-message {
    color: $fh-red-reversed;
  }
}