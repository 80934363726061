.usergroup-list-container {
  padding: 24px;

  .grid-item {
    padding-top: 20px;
    padding-bottom: 20px;

    .item-paper {
      color: #fff;
      background-color: #29313e;
      padding: 20px;
    }

    .table-container {
      .table-header {
        .header-cell {
          border-right: solid 1px #515151;
        }
      }

      .table-body {
        .icon-button {
          cursor: pointer;
        }
      }
    }
  }

}