@import '../../../styles/consts.scss';

.fh {
  .login-form-page {
    height: calc(100% - 50px);
    width: 100%;
    background-color: $fh-bunker;
    color: white;

    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    @include mobile {
      display: block;
      background: $fh-ebonyclay;
    }

    .login-form-container {
      position: relative;

      @include mobile {
        height: 100%;
      }

      .login-form {
        width: 440px;
        padding: 8px;
        border-radius: 5px;
        background: $fh-ebonyclay;

        @include mobile {
          width: 100%;
          border-radius: 0;
          padding: 0;
        }

        &-header {
          text-align: center;
          padding-bottom: 8px;
          border-bottom: 2px solid $fh-riverbed;
          color: $fh-key-blue-reversed;
          font-weight: $medium-weight;

          @include mobile {
            display: none;
          }
        }

        &-message {
          padding: 10px 10px;
          padding-bottom: 0;

          @include mobile {
            text-align: center;
            border-bottom: 1px solid $fh-riverbed;
            padding-bottom: 10px;
          }
        }

        &-body {
          margin: 30px 0;
          padding: 0 20px;
          padding-right: 40px;

          .fh-form-field-left {
            width: unset;
            min-width: unset;
            flex: 1 1;
          }
          .fh-form-field-right {
            flex: 2 2;
          }

          @include mobile {
            padding: 0 20px;
          }
        }

        &-error {
          color: $fh-red-reversed;
          padding: 0 20px;
          margin-bottom: 20px;

          @include mobile {
            padding-left: 62px;
          }
        }

        &-footer {
          text-align: right;

          button {
            width: 100%;
            justify-content: center;
          }

          @include mobile {
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: 4px;
            text-align: center;
            box-shadow: 0px -2px 8px -2px #00000054;

            button {
              border-radius: 50px;
              width: auto;
              width: 200px;
              max-width: 100%;
              text-transform: none;
            }
          }
        }
      }

      .base-form-links {
        width: 100%;
        padding-top: 10px;
        display: flex;
        justify-content: space-between;

        @include mobile {
          padding: 0 20px;
          position: absolute;
          bottom: 70px;
        }

        a {
          color: white;
          font-weight: normal;
        }
      }
    }
  }
}