@import './consts.scss';
@import '~@fh-components/styles/src/functions';


html {
  height: 100%;
  margin: 0;
  padding: 0;


  font-family: $fontFamily;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: $regular-font;
  font-weight: $regular-weight;
  background: $fh-ebonyclay-dark;
  scrollbar-color: rgba(255,255,255,0.7) rgba(0,0,0,0);
}

body {
  margin: 0;
  height: 100%;
}

#root {
  height: 100%;
}

* {
  box-sizing: border-box;
}

//fh-component overrides
.fh {
  .MuiTablePagination-root {
    .MuiTablePagination-selectRoot {
      font-size: 0.975rem;

      .MuiSelect-icon {
        color: rgba(255,255,255,0.7);
      }
    }

    .MuiIconButton-root {
      color: rgba(255,255,255,0.7);

      &.Mui-disabled {
        color: rgba(255,255,255,0.3);
      }
    }

    .MuiTypography-caption {
      font-size: 0.85rem;
      font-weight: 600;
    }
  }

  .MuiTooltip-tooltip {
    font-size: 12px;
  }

  .MuiDialogActions-root {
    background-color: $fh-slate;
  }

  .fh-button {
    &.primary {
      color: rgba(0, 0, 0, .87);
      background: $fh-amber;
  
      &:hover {
        background: lighten($fh-amber, 5%);
      }
  
      &:active {
        background: darken($fh-amber, 5%);
      }
  
      &.loading {
        @include stripes($fh-amber);
      }
  
      &:focus {
        box-shadow:
          0 0 0 1px $fh-ebonyclay,
          0 0 0 4px $fh-amber;
      }
    }
    &.secondary {
      color: $fh-amber;
      border: 2px solid $fh-amber;

      svg {
        fill: $fh-amber;
      }

      &:hover {
        color: lighten($fh-amber, 5%);
        border-color: lighten($fh-amber, 5%);
      }

      &:active {
        color: darken($fh-amber, 5%);
        border-color: darken($fh-amber, 5%);
      }

      &.loading {
        @include stripes($fh-ebonyclay);
      }

      &:focus {
        box-shadow:
          0 0 0 1px $fh-ebonyclay,
          0 0 0 4px $fh-amber;
      }
    }
  }
}

//material-table overrides
.MuiToolbar-root [aria-label="Clear Search"] {
  color: whitesmoke;
  cursor: pointer;
}