@import '../../../styles/consts.scss';

.users-list-toolbar {
  margin: 17px 0px;

  .left-side {
    display: flex;
    align-items: center;
    animation-duration: 300ms;
    animation-name: flipin;

    .MuiDivider-root {
      margin: 0px 10px;
    }

    .MuiAvatar-root {
      font-size: 1.25rem;
      font-weight: 600;
      width: 40px;
      border-radius: 33%;
      margin-left: 5px;
      color: black;
      background-color: $fh-amber;
    }

    .fh-button {
      margin-right: 10px
    }
  }
}

@keyframes flipin {
  from {
    transform: rotateX(-0.25turn);
  }
  to {
    transform: rotateX(0);
  }
}
