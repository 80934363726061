.users-list {
  color: white;

  .MuiBackdrop-root {
    z-index: 2;
  }
  .top-button-row {
    margin-top: 14px;
    margin-bottom: 14px
  }

  .toolbar-container {
    position: sticky;
    top: -17px;
    z-index: 1
  }

  .table-container {
    z-index: 0;
  }
}