@import '../../../styles/consts.scss';


.fh {
  .bulk-add-users-to-group-modal {
    color: white;

    .MuiPaper-root.MuiDialog-paper .MuiDialogContent-root {
      display: flex;
      flex-direction: column;
      padding-bottom: 0px;

      .groupsList-container {
        padding-bottom: 10px;
        overflow-y: scroll;

        &.loading {
          overflow-y: hidden;
          position: relative;

          .loader {
            position: absolute;
            height: 100%;
            width: 100%;
            z-index: 11;
            background-color: $fh-ebonyclay-dark;
            opacity: 0.7;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        .loader {
          display: none;
        }
      }

      .addGroups-container {
        margin-top: 10px;
      }
    }
  }
}
/*  AddGroups component's autocomplete popper  */
.MuiAutocomplete-popper .MuiPaper-root.MuiAutocomplete-paper {
  background-color: lighten($fh-ebonyclay, 5%);
  border: 2px solid $fh-ebonyclay-dark;

  &:empty {
    border: none;
  }
}


