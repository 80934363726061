@import '~@fh-components/styles/src/const.scss';
@import '~@fh-components/styles/src/theme-dark.scss';
@import '~@fh-components/styles/src/theme-light.scss';
@import '~@fh-components/styles/src/mixins';
@import '~@fh-components/form/dist/index';
@import '~@fh-components/navbar/dist/Navbar.css';
@import '~@fh-components/button/dist/Button.css';

$fontFamily: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
sans-serif;

$fh-active: $fh-amber;

:export {
  fontFamily: $fontFamily;
  ebonyclay: $fh-ebonyclay;
  active: $fh-active;
  ebonyclayDark: $fh-ebonyclay-dark;
  riverbed: $fh-riverbed;
  bunker: $fh-bunker;
  grayChateau: $fh-gray-chateau;
  amber: $fh-amber;
}