.error-container {
  height: 100%;
}

.MuiCard-root {
  width: 500px
}

.MuiCardMedia-root {
  height: 300px;
  width: 500px;
  // background-color: #48324a;
  background-color: #ffffff60;
}

.image-copyright {
  position: absolute;
  bottom: 3px;
  right: 3px;

  a {
    color:#48324a;
    text-decoration: none;
  }
}