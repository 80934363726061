@import '../../../styles/consts.scss';

.fh {
  .bulk-remove-users-from-group-modal {
    color: white;

    .MuiPaper-root.MuiDialog-paper .MuiDialogContent-root {
      display: flex;
      flex-direction: column;
      padding-bottom: 0px;

      .groups-list {
        padding-bottom: 10px;
        overflow-y: scroll;
      }
    }
  }
}