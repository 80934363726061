@import '../../../styles/consts.scss';


.fh {
  .edit-user-modal {
    color: white;

    .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper {
      overflow: hidden;
      height: 100%;

      .fh-form {
        display: inherit;
        flex-direction: inherit;
        height: 100%;

        .form-fields{
          .fh-form-field-left {
            width: unset;
            min-width: unset;
            flex: 2;
          }
          .fh-form-field-right {
            flex: 5;
          }
        }
      }

      .MuiDialogContent-root {
        display: flex;
        flex-direction: column;
        padding-bottom: 0px;

        .groupsList-container {
          padding-bottom: 10px;

          &.loading {
            overflow-y: hidden;
            position: relative;

            .loader {
              position: absolute;
              height: 100%;
              width: 100%;
              z-index: 11;
              background-color: $fh-ebonyclay-dark;
              opacity: 0.7;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }

          .loader {
            display: none;
          }
        }

        .addGroups-container {
          margin-top: 10px;
        }
      }

      .MuiDialogActions-root {
        justify-content: flex-start;

        .modalMessage {
          flex: 1 0;
          margin-top: 0px;
        }

        .fh-button {

          &.secondary {
            order: 1;
          }
          &.primary {
            order: 2;
            margin-left: 8px;
          }
        }
      }
    }

  }
}
/*  AddGroups component's autocomplete popper  */
.MuiAutocomplete-popper .MuiPaper-root.MuiAutocomplete-paper {
  background-color: lighten($fh-ebonyclay, 5%);
  border: 2px solid $fh-ebonyclay-dark;

  &:empty {
    border: none;
  }
}


